import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
    doubleHeadTubeFilling, gearPumpLiquidFilling, liquidFilling, rinsingFillingAndCapping, semiAutomaticTubeFilling, singleHeadTubeFilling,
    timeBasedLiquidFilling, viscousPneumaticPistonFilling, volumetricLiquidFilling, volumetricLiquidVialFilling, weighMetricOilFilling,
} from "assets";
import { SolutionCard } from "components";

const LiquidFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    let currentSolution = null;

    const elements = [
        { id: 1, title: "Volumetric Liquid Filling", slug: "volumetric-liquid-filling", image: volumetricLiquidFilling, },
        { id: 2, title: "Gear Pump Liquid Filling", slug: "gear-pump-liquid-filling", image: gearPumpLiquidFilling, },
        { id: 3, title: "Rinsing, Filling and Capping", slug: "rinsing-filling-and-capping", image: rinsingFillingAndCapping, },
        { id: 4, title: "Time-Based Liquid Filling", slug: "time-based-liquid-filling", image: timeBasedLiquidFilling, },
        { id: 5, title: "Weigh Metric Oil Filling", slug: "weigh-metric-oil-filling", image: weighMetricOilFilling, },
        { id: 6, title: "Viscous Pneumatic Piston Filling", slug: "viscous-pneumatic-piston-filling", image: viscousPneumaticPistonFilling, },
        { id: 7, title: "Volumetric Liquid Vial Filling with Rubber Stoppering", slug: "volumetric-liquid-vial-filling-with-rubber-stoppering", image: volumetricLiquidVialFilling, },
        { id: 8, title: "Single Head Tube Filling", slug: "single-head-tube-filling", image: singleHeadTubeFilling, },
        { id: 9, title: "Double Head Tube Filling", slug: "double-head-tube-filling", image: doubleHeadTubeFilling, },
        { id: 10, title: "Semi-Automatic Tube Filling", slug: "semi-automatic-tube-filling", image: semiAutomaticTubeFilling, }
    ];

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Liquid Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"}`}>
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container
                fluid
                className="my-5 pt-5 bg-white"
                style={{ minHeight: "50vh" }}
            >
                <Container>
                    <Row>
                        {elements.map((item) => (
                            <Col className="pb-4" lg={4}>
                                <SolutionCard
                                    solution={item}
                                    currentSolution={currentSolution}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default LiquidFilling;
