import React, { useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { notify } from "utilities/Notify";
import axios from "axios";

const ContactUsForm = () => {
  const form = useRef();

  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setIsSending(true);

    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phoneCode: formData.get("phone_code"),
      phone: formData.get("phone"),
      message: formData.get("message"),
    }

    try {
      const response = await axios.post('/submit-contact', { ...data }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        notify(response.data, "success")
      }
      setIsSending(false);
    } catch (error) {
      notify("There was an issue while submitting the form. Please try again!", "error")
      console.error('Error:', error);
      setIsSending(false);
    }
  };

  return (
    <Form ref={form} onSubmit={handleSubmit}>
      <Form.Group className="mt-4">
        <Form.Label className="body-text1">Full Name</Form.Label>
        <Form.Control
          name="name"
          placeholder="Full Name"
          type="text"
          required
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label className="body-text1">Email</Form.Label>
        <Form.Control
          name="email"
          placeholder="company.name@example.com"
          type="text"
          required
          className="bg-white px-4"
          style={{
            height: 60,
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label className="body-text1">Phone</Form.Label>
        <div className="d-flex">
          <Form.Control
            name="phone_code"
            placeholder="+91"
            type="text"
            required
            className="bg-white px-3 me-2"
            style={{
              height: 60,
              width: 80,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
          <Form.Control
            name="phone"
            placeholder="99999 99999"
            type="text"
            required
            className="bg-white px-4"
            style={{
              height: 60,
              borderRadius: 30,
              border: "1px solid #D0D5DD",
            }}
          />
        </div>
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label className="body-text1">Message</Form.Label>
        <Form.Control
          name="message"
          as="textarea"
          rows={5}
          placeholder="Leave Us a Message"
          type="text"
          required
          className="bg-white p-4"
          style={{
            borderRadius: 30,
            border: "1px solid #D0D5DD",
          }}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Check
          type="checkbox"
          required
          label="You agree to our friendly privacy policy."
        />
      </Form.Group>
      <Button
        className="my-4 bg-primary text-white primary-button manrope-600 w-100"
        disabled={isSending}
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 30,
          height: 60,
        }}
        type="submit"
      >
        {isSending ?
          <span>
            <Spinner size="sm" className="me-2" />
            Sending...
          </span>
          :
          <span>Send</span>
        }
      </Button>
    </Form>
  );
};

export default ContactUsForm;
