import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, volumetricLiquidVialFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const VolumetricLiquidVialFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "S.S. Frame Structure (GMP Model) ensures compliance with Good Manufacturing Practices.",
        "Filling accuracy better than 1%, ensuring precision in dosage.",
        "Contact parts crafted from SS-316, ensuring durability and adherence to quality standards.",
        "Variable Speed Infeed Turn Table (30\" Dia.) for flexible and efficient vial handling.",
        "PLC with MMI- 3.8\" Mono Screen for intuitive and user-friendly operation.",
        "High precision manufactured syringes (10 Nos.) for consistent and reliable performance.",
        "Servo motor-driven syringes, contributing to enhanced accuracy and efficiency.",
        "Fill volume setting through PLC, allowing for easy customization.",
        "Separate variable speed conveyor for optimized production flow.",
        "Pre and post gassing system for product freshness and longevity.",
        "Disc type rubber stoppering system (vacuum-based) for versatile stoppering solutions.",
        "No Vial - No Fill arrangement for process integrity and waste reduction.",
        "Suitable for plain as well as slotted stoppers, facilitated by change parts.",
        "Acrylic cabinet (optional), providing an additional layer of visibility and aesthetic appeal.",
        "Vacuum pump for rubber stoppering unit (optional), enhancing the stoppering process.",
        "Extra servo system for syringe (optional), offering additional control and precision."
    ]


    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Volumetric Liquid Vial Filling with Rubber Stoppering</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Volumetric Liquid Vial Filling with Rubber Stoppering
                        </span>
                    </div>
                    <Row className={`${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={volumetricLiquidVialFilling}
                                alt="Volumetric Liquid Vial Filling with Rubber Stoppering Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8} style={{ textAlign: 'justify' }}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The Automatic Volumetric Liquid Vial Filling with Rubber Stoppering Machine is
                                designed for round vials, offering a maximum speed of 240 vials per minute. The
                                operational efficiency depends on factors such as the nature of liquids, vial
                                neck size, fill volume, and rubber stopper size. The machine features built-in
                                turntables at both the in-feed and out-feed stages, each equipped with individual
                                drives to synchronize with the speed of incoming and outgoing vials, ensuring a
                                seamless and efficient operation.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Additionally, this machine is available with a vacuumetric-type Rubber Stoppering
                                System, providing a versatile solution to meet specific production requirements.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default VolumetricLiquidVialFilling;
