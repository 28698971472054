import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaAnglesUp, FaAnglesDown } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";

const FeaturePoints = ({ items, variant = false }) => {
    const [showAll, setShowAll] = useState(false);
    const visibleItems = showAll ? items : items.slice(0, 4);

    return (
        <>
            {visibleItems.map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`my-2 p-4 d-flex align-items-center feature-point ${variant ? "justify-content-center" : "w-100"}`}
                        style={{
                            backgroundColor: "#ffebb1",
                            transition: "transform 0.3s ease"
                        }}
                    >
                        <MdDoubleArrow size={25} color="#eabe21" />
                        <span className="subtitle ps-2" style={{ textAlign: 'justify' }}>{item}</span>
                    </div>
                );
            })}
            {items.length > 4 && (
                <div className="text-center mt-3">
                    <Button
                        className="bg-white border-0"
                        onClick={() => setShowAll(!showAll)}
                    >
                        {showAll ?
                            <div className="d-flex justify-content-center align-items-center">
                                <span className="subtitle me-2">Read Less</span>
                                <FaAnglesUp size={15} />
                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center">
                                <span className="subtitle me-2">Read More</span>
                                <FaAnglesDown size={15} />
                            </div>
                        }
                    </Button>
                </div>
            )}
        </>
    );
};

export default FeaturePoints;
