import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { consultancy, feasibilityReport, deatiledDesign } from "assets";
import { ShareCard } from "components";

const Consultancy = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={consultancy}
                    alt="Consultancy"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Consultancy</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "ms-4" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className="my-lg-5 pt-lg-5">
                        <div>
                            <h3 className="text-tertiary mt-4">Feasibility Report</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                        </div>
                        <Col sm={12} md={8} lg={8}>
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                A feasibility study is conducted prior to a project’s undertaking such as a Pharmaceutical
                                and Cosmetics project. Principals of companies and their investors want to ensure that
                                any given project they plan on developing is actually “feasible”, and preparing a study
                                showing this feasibility is the main point of writing such a report (which of course
                                will help save needed capital and time in the long run). A feasibility study includes
                                such vital information and data as the funding needs to complete the project, the market
                                opportunity, government regulations, risk factors, strength and weaknesses, the management
                                team and the financials of the company. While a feasibility study somewhat sounds like a
                                business plan, such reports tend to be many times longer with more details on the market and financials.
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100 mt-4"
                                src={feasibilityReport}
                                alt="Feasibility Report"
                                style={{ borderRadius: 20 }}
                            />
                        </Col>
                    </Row>
                    <div>
                        <h3 className="text-tertiary mt-5">Conceptual Design</h3>
                        <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    </div>
                    <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                        The conceptual engineering project phase serves to identify the technical and economic feasibility
                        of the project, and how we can set the course for the development of basic and detailed engineering.
                    </div>
                    <div className="mt-5">
                        <h3 className="text-tertiary">Basic Design</h3>
                        <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    </div>
                    <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                        One or two solutions that emerge as the most suitable in the previous phase, details of the design
                        are given including field studies, especially of a topographical, geological, geotechnical,
                        hydrological and hydraulic nature. Site Master Planning, Conceptual Design Development, cGMP Layout,
                        cGMP & EHS Risk Assessment, Containment Strategies, General Planning & Engineering, Equipment Sizing,
                        Production Capacity Planning, Basis of Design.
                    </div>
                    <Row className="my-5">
                        <div>
                            <h3 className="text-tertiary">Detailed Design</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                        </div>
                        <Col sm={12} md={7} lg={7} style={{ textAlign: 'justify' }}>
                            <div className="subtitle text-gray mt-4">
                                These include all the construction details by discipline (Civil, Mechanical, Process, Electric,
                                Instrumentation and Control, Computer Systems) which must be approved for construction.
                                This process serves to provide a closed project cost estimate.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Tender Documents for Architectural, Civil, Structural, Process, Mechanical, HVAC, Piping, Clean Rooms,
                                Electrical, Instrumentation, BMS, EMS, Fire Fighting, Clean Utilities, Black Utilities, Warehouse,
                                QA/QC. Bill of Quantity, Revit 3D Design on BIM Platform, Technical Data Sheets, Technical
                                Specifications, Approved Makes List, Good for Construction Drawings.
                            </div>
                        </Col>
                        <Col sm={12} md={5} lg={5}>
                            <Image
                                className="w-100"
                                src={deatiledDesign}
                                alt="Detailed Design"
                                style={{ borderRadius: 20 }}
                            />
                        </Col>
                        <div className="mt-5">
                            <ShareCard share_url={share_url} bottom={false} />
                        </div>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default Consultancy;
