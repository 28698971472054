import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, viscousPneumaticPistonFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const ViscousPneumaticPistonFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Pneumatic-based dripping tray, ensuring efficient handling of potential drips.",
        "Pneumatic neck holding system for secure bottle positioning during the filling process.",
        "Stainless steel flexible pipes for hazardous product applications, ensuring safety and durability.",
        "Utilizes reliable and proven pneumatic components for consistent performance."
    ]

    const optionalFeatures = [
        "Polycarbonate enclosure in stainless steel structure, offering enhanced visibility and durability.",
        "Flame-proof motor and control panel for increased safety in hazardous environments.",
        "Pre and post nitrogen flushing system, contributing to product freshness and shelf life.",
        "In-feed turntable for continuous bottle feeding, optimising production efficiency."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Viscous Pneumatic Piston Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Viscous Pneumatic Piston Filling
                        </span>
                    </div>
                    <Row className={`${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={viscousPneumaticPistonFilling}
                                alt="Viscous Pneumatic Piston Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8} style={{ textAlign: 'justify' }}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The Automatic Viscous Pneumatic Piston Filling Machine, featuring a Servo-based
                                operating system, caters to a diverse range of industries including Food & Beverage,
                                Cosmetics, Personal Care, Agricultural, Animal Care, Pharmaceutical, and Chemical
                                fields. Renowned for its flexibility, this Servo Piston Filler excels in
                                volumetrically filling liquids across a broad viscosity spectrum—from low thin
                                viscosity liquids to high viscosity liquids or creams, with or without particulates.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The product delivery mechanism from the bulk tank to the pistons is highly customizable.
                                It can be configured with a buffer tank utilising a level-sensing float or through
                                the manifold using direct draw or recirculation methods, offering adaptability to
                                various production requirements.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Features :</h3>
                    <FeaturePoints items={optionalFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default ViscousPneumaticPistonFilling;
