import React from "react";
import { privacyPolicy, tata } from "assets";
import { DiamondCard } from "components";
import { useMediaQuery } from "react-responsive";
import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const clientsData = [
    { id: 1, title: "Tata", image: tata },
    { id: 2, title: "Tata", image: tata },
    { id: 3, title: "Tata", image: tata },
    { id: 4, title: "Tata", image: tata },
    { id: 5, title: "Tata", image: tata },
    { id: 6, title: "Tata", image: tata },
    { id: 7, title: "Tata", image: tata },
    { id: 8, title: "Tata", image: tata },
    { id: 9, title: "Tata", image: tata },
    { id: 10, title: "Tata", image: tata },
    { id: 11, title: "Tata", image: tata },
    { id: 12, title: "Tata", image: tata },
    { id: 13, title: "Tata", image: tata },
    { id: 14, title: "Tata", image: tata },
    { id: 15, title: "Tata", image: tata },
    { id: 16, title: "Tata", image: tata },
    { id: 17, title: "Tata", image: tata },
    { id: 18, title: "Tata", image: tata },
    { id: 19, title: "Tata", image: tata },
];

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const xsmall = useMediaQuery({ query: "(min-width: 360px)" });
    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

    //   const [result, setResult] = useState(null);

    const diamondCardStyle = {
        width: xlarge
            ? 200
            : large
                ? 160
                : medium
                    ? 140
                    : small
                        ? 120
                        : xsmall
                            ? 100
                            : 80,
        height: xlarge
            ? 200
            : large
                ? 160
                : medium
                    ? 140
                    : small
                        ? 120
                        : xsmall
                            ? 100
                            : 80,
    };

    function divideArray(arr, sizes) {
        const dividedArrays = [];
        let currentIndex = 0;

        for (const size of sizes) {
            if (currentIndex >= arr.length) {
                break;
            }

            const subarray = arr.slice(currentIndex, currentIndex + size);
            dividedArrays.push(subarray);

            currentIndex += size;
        }

        return dividedArrays;
    }

    const subarraySizes = large ? [3, 4, 5, 4, 3] : [1, 2, 3, 2, 3, 2, 3, 2, 1];
    const result = divideArray(clientsData, subarraySizes);

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={privacyPolicy}
                    alt="Privacy Policy"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Policies</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Privacy Policy</div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white py-5">
                <Container className="d-flex flex-column justify-content-center" style={{ textAlign: 'justify' }}>
                    <div className="subtitle text-gray">
                        At Gemtech Solutions, we are committed to protecting the privacy and security of our customers
                        and website visitors. This Privacy Policy explains how we collect, use, and safeguard your
                        personal information when you visit our website or use our services.
                    </div>

                    <h3 className="text-tertiary mt-5">Information We Collect</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        <span>
                            We may collect the following types of information from you when you interact with our
                            website or services:
                        </span>
                        <b>a. Personal Information</b>
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Company name</li>
                            <li>Address</li>
                            <li>Any other information you provide when contacting us or filling out forms on our site.</li>
                        </ul>
                        <b>b. Non-Personal Information</b>
                        <ul>
                            <li>IP address</li>
                            <li>Browser type</li>
                            <li>Operating system</li>
                            <li>Referring website</li>
                            <li>Pages viewed on our website</li>
                            <li>Date and time of your visit</li>
                            <li>Interaction with our site (e.g., clicks, scrolling).</li>
                        </ul>
                    </div>

                    <h3 className="text-tertiary mt-5">How We Use Your Information</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        We use the information we collect for the following purposes:
                        <ul>
                            <li>
                                <b>To Provide and Improve Our Services: </b>
                                We use personal information to process your inquiries, requests, or orders, and to provide better
                                services.
                            </li>
                            <li>
                                <b>Communication: </b>
                                We may use your contact information to send you updates, newsletters, or marketing communications,
                                which you can opt out of at any time.
                            </li>
                            <li>
                                <b>Personalization: </b>
                                To enhance your browsing experience by showing you content tailored to your preferences.
                            </li>
                            <li>
                                <b>Analytics: </b>
                                To monitor the performance of our website, understand visitor preferences, and improve our site and
                                services.
                            </li>
                        </ul>
                    </div>

                    <h3 className="text-tertiary mt-5">Cookies and Tracking Technologies</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        We use cookies and similar technologies to track your activity on our website and store
                        certain information. Cookies help us provide a better browsing experience, and you can
                        choose to disable them through your browser settings, although this may impact your ability
                        to use certain features of our website.
                    </div>

                    <h3 className="text-tertiary mt-5">How We Protect Your Information</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        We take data security seriously and implement appropriate technical, administrative, and physical
                        safeguards to protect your personal information from unauthorized access, use, or disclosure.
                        Despite these measures, no method of transmission over the Internet or method of electronic
                        storage is 100% secure. We cannot guarantee absolute security, but we continuously work to
                        improve our security practices.
                    </div>

                    <h3 className="text-tertiary mt-5">Sharing Your Information</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        We do not sell, trade, or otherwise transfer your personal information to outside parties except
                        as follows:
                        <ul>
                            <li>
                                <b>Service Providers: </b>
                                We may share your information with trusted third-party service providers who assist us in
                                operating our website, conducting our business, or providing services to you, under strict
                                confidentiality agreements.
                            </li>
                            <li>
                                <b>Legal Requirements: </b>
                                We may disclose your information if required by law, to comply with a legal process, or to
                                protect our rights, safety, and property.
                            </li>
                        </ul>
                    </div>

                    <h3 className="text-tertiary mt-5">Your Choices and Rights</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        You have the following rights concerning your personal data:
                        <ul>
                            <li>
                                <b>Access: </b>
                                You may request access to the personal information we hold about you.
                            </li>
                            <li>
                                <b>Correction: </b>
                                You can request correction of any inaccurate or incomplete information.
                            </li>
                            <li>
                                <b>Deletion: </b>
                                You may request deletion of your personal data, subject to legal obligations.
                            </li>
                            <li>
                                <b>Opt-Out: </b>
                                You can opt out of receiving marketing communications from us at any time by
                                following the unsubscribe instructions in our emails or contacting us directly.
                            </li>
                        </ul>
                    </div>

                    <h3 className="text-tertiary mt-5">Links to Third-Party Sites</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        Our website may contain links to third-party websites or services that are not owned or
                        controlled by Gemtech Solutions. We are not responsible for the privacy practices of these
                        third-party sites. We encourage you to review the privacy policies of any site you visit.
                    </div>

                    <h3 className="text-tertiary mt-5">Children's Privacy</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        Our website is not directed toward individuals under the age of 13. We do not knowingly
                        collect personal information from children. If you believe we have collected personal
                        information from a child without parental consent, please contact us, and we will take
                        appropriate action to delete the information.
                    </div>

                    <h3 className="text-tertiary mt-5">Changes to This Privacy Policy</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        We may update this Privacy Policy from time to time to reflect changes in our practices,
                        technology, or legal requirements. Any changes will be posted on this page with an updated
                        effective date.
                    </div>

                    <h3 className="text-tertiary mt-5">Contact Us</h3>
                    <div className="bg-primary" style={{ width: 100, height: 4 }} />
                    <div className="subtitle text-gray mt-2 d-flex flex-column">
                        If you have any questions or concerns about this Privacy Policy or how we handle your personal
                        information, please contact us at:
                        <b>Gemtech Solutions</b>
                        <span>Email: info@gemtechs.in</span>
                        <span>Phone: +91 99303 29749</span>
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
