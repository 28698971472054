import React from "react";
import { clients, tata } from "assets";
import { DiamondCard } from "components";
import { useMediaQuery } from "react-responsive";
import { Button, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const clientsData = [
    { id: 1, title: "Tata", image: tata },
    { id: 2, title: "Tata", image: tata },
    { id: 3, title: "Tata", image: tata },
    { id: 4, title: "Tata", image: tata },
    { id: 5, title: "Tata", image: tata },
    { id: 6, title: "Tata", image: tata },
    { id: 7, title: "Tata", image: tata },
    { id: 8, title: "Tata", image: tata },
    { id: 9, title: "Tata", image: tata },
    { id: 10, title: "Tata", image: tata },
    { id: 11, title: "Tata", image: tata },
    { id: 12, title: "Tata", image: tata },
    { id: 13, title: "Tata", image: tata },
    { id: 14, title: "Tata", image: tata },
    { id: 15, title: "Tata", image: tata },
    { id: 16, title: "Tata", image: tata },
    { id: 17, title: "Tata", image: tata },
    { id: 18, title: "Tata", image: tata },
    { id: 19, title: "Tata", image: tata },
];

const Clients = () => {
    const navigate = useNavigate();
    const xsmall = useMediaQuery({ query: "(min-width: 360px)" });
    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

    //   const [result, setResult] = useState(null);

    const diamondCardStyle = {
        width: xlarge
            ? 200
            : large
                ? 160
                : medium
                    ? 140
                    : small
                        ? 120
                        : xsmall
                            ? 100
                            : 80,
        height: xlarge
            ? 200
            : large
                ? 160
                : medium
                    ? 140
                    : small
                        ? 120
                        : xsmall
                            ? 100
                            : 80,
    };

    function divideArray(arr, sizes) {
        const dividedArrays = [];
        let currentIndex = 0;

        for (const size of sizes) {
            if (currentIndex >= arr.length) {
                break;
            }

            const subarray = arr.slice(currentIndex, currentIndex + size);
            dividedArrays.push(subarray);

            currentIndex += size;
        }

        return dividedArrays;
    }

    const subarraySizes = large ? [3, 4, 5, 4, 3] : [1, 2, 3, 2, 3, 2, 3, 2, 1];
    const result = divideArray(clientsData, subarraySizes);

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={clients}
                    alt="Clients"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Clients</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Clients</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Empowering Success Through Industry Partnerships and Connecting Innovation to Success.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-secondary py-5">
                <div
                    className="content-wrapper justify-content-center"
                >
                    {result && (
                        <div className="d-flex flex-column">
                            <div className="d-flex mx-auto">
                                {result[0]?.map((item) => (
                                    <DiamondCard
                                        title={item.title}
                                        image={item.image}
                                        style={diamondCardStyle}
                                    />
                                ))}
                            </div>
                            {result?.slice(1)?.map((res, i) => (
                                <div
                                    className="d-flex mx-auto"
                                    style={{ marginTop: -diamondCardStyle.width / 2 }}
                                >
                                    {result[i + 1]?.map((item) => (
                                        <DiamondCard
                                            title={item.title}
                                            image={item.image}
                                            style={{ ...diamondCardStyle }}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </>
    );
};

export default Clients;
