import React from "react";
import { Container, Button, Card, Image, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import {
  blisterPacking,
  coating,
  consultancy,
  encapsulation,
  granulation,
  liquidFilling,
  solutionsVideo,
  spareParts,
  tableting,
} from "assets";
import { Outlet, useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import "./Solutions.scss";
import { FiArrowUpRight } from "react-icons/fi";


const Solutions = () => {
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const navigate = useNavigate();

  const solutions = [
    {
      id: 1,
      title: "Consultancy",
      description: "Consultancy",
      url: "consultancy",
      image: consultancy,
    },
    {
      id: 2,
      title: "Granulation",
      description: "Granulation",
      url: "granulation",
      image: granulation,
    },
    {
      id: 3,
      title: "Tableting",
      description: "Tableting",
      url: "tableting",
      image: tableting,
    },
    {
      id: 4,
      title: "Coating",
      description: "Coating",
      url: "coating",
      image: coating,
    },
    {
      id: 5,
      title: "Encapsulation",
      description: "Encapsulation",
      url: "encapsulation",
      image: encapsulation,
    },
    {
      id: 6,
      title: "Blister Packing & Cartoning",
      description: "Blister Packing & Cartoning",
      url: "blister-packing-and-cartoning",
      image: blisterPacking,
    },
    {
      id: 7,
      title: "Liquid Filling",
      description: "Liquid Filling",
      url: "liquid-filling",
      image: liquidFilling,
    },
    {
      id: 8,
      title: "Change Parts & Spare Parts",
      description: "Change Parts & Spare Parts",
      url: "change-parts-and-spare-parts",
      image: spareParts,
    },
  ];

  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "50vh" }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className="position-absolute w-100 h-100"
          style={{ objectFit: "cover", zIndex: "-1" }}
        >
          <source src={solutionsVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="position-absolute w-100 h-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
        ></div>
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="px-lg-3 text-primary body-text2">
            <b>Our Solutions</b>
          </div>
          <div className="page-header text-white my-lg-3 my-2">Solutions</div>
          <div className="subtitle-400 text-white text-center mb-5">
            Your needs, our expertise. Find the perfect solution.
          </div>
        </Container>
      </Container>
      <Container fluid className="bg-white py-5">
        <Container>
          <Row className={`${large ? "row-cols-2" : "row-cols-1"}`}>
            {solutions.map((solution) => {
              return (
                <Col className={`d-flex justify-content-center align-items-center ${large ? "" : "px-0"}`}>
                  <Card className={`solution-page-solution-card ${large ? "my-4" : "my-2"}`}>
                    <Image src={solution.image} alt={solution.title} className="card__img" />
                    <span className="card__footer">
                      <h3 className="py-3 px-4 text-primary">{solution.title}</h3>
                    </span>
                    <span className="card__action">
                      {/* <Button
                        className="bg-primary text-tertiary primary-button"
                        style={xlarge ?
                          {
                            paddingLeft: 30,
                            paddingRight: 30,
                            borderRadius: 30,
                            height: 60,
                          }
                          :
                          {
                            paddingLeft: 20,
                            paddingRight: 20,
                            borderRadius: 20,
                            height: 40,
                          }
                        }
                        onClick={() => navigate(`/solutions/${solution.url}`)}
                      >
                        <span className="manrope-600">Learn More</span>
                      </Button> */}
                      <Button
                        className="bg-primary text-tertiary border-tertiary"
                        style={xlarge ?
                          {
                            borderRadius: 25,
                            width: 50,
                            height: 50,
                          }
                          :
                          {
                            borderRadius: 20,
                            width: 40,
                            height: 40,
                          }
                        }
                        onClick={() => navigate(`/solutions/${solution.url}`)}
                      >
                        <FiArrowUpRight size={20} />
                      </Button>
                    </span>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Container>
      <Outlet />
    </>
  );
};

export default Solutions;
