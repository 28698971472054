import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breakpoint } from "react-socks";

import { CgMenu } from "react-icons/cg";
import { FiChevronDown } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import { gemtechTextLogo } from "assets";
import { IoIosMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { GoMail } from "react-icons/go";

import { useTransition, animated } from "react-spring";

const NavBar = () => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  // const param2 = params.length > 1 ? params[1] : "";

  const [solutionDropdown, setSolutionDropdown] = useState(false);
  const [solutionHover, setSolutionHover] = useState(false);
  const [dropdownSolution, setDropdownSolution] = useState(null);
  const solutions = [
    {
      id: 1,
      title: "Consultancy",
      slug: "consultancy",
      is_visible: true,
    },
    {
      id: 2,
      title: "Granulation",
      slug: "granulation",
      is_visible: true,
    },
    {
      id: 3,
      title: "Tableting",
      slug: "tableting",
      is_visible: true,
    },
    {
      id: 4,
      title: "Coating",
      slug: "coating",
      is_visible: true,
    },
    {
      id: 5,
      title: "Encapsulation",
      slug: "encapsulation",
      is_visible: true,
    },
    {
      id: 6,
      title: "Blister Packing & Cartoning",
      slug: "blister-packing-and-cartoning",
      is_visible: true,
    },
    {
      id: 7,
      title: "Liquid Filling",
      slug: "liquid-filling",
      is_visible: true,
    },
    {
      id: 8,
      title: "Change Parts & Spare Parts",
      slug: "change-parts-and-spare-parts",
      is_visible: true,
    },
  ];

  const [open, setOpen] = useState(false);

  const transition = useTransition(open, {
    from: { y: "-100%" },
    enter: { y: "0%" },
    leave: { y: "-100%" },
    config: { duration: 300 },
  });

  const activeBar = {
    borderLeft: "4px solid black",
    weight: "500",
    lineHeight: "24px",
    size: "20px",
    fontFamily: "Manrope",
  };

  const nonactiveBar = {
    weight: "500",
    lineHeight: "24px",
    size: "20px",
    fontFamily: "Manrope",
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 300;
      setIsScrolled(scrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Breakpoint large up>
        <Navbar
          fixed="top"
          className={`${(isScrolled || param1 !== "") ? "bg-primary" : "bg-faded"}`}
          style={{ minHeight: 80 }}
        >
          <Container fluid className="px-5 py-0">
            {(isScrolled || param1 !== "") &&
              <Navbar.Brand href="/" className="p-0 d-flex align-items-center">
                <Image src={gemtechTextLogo} height={60} loading="lazy" />
              </Navbar.Brand>
            }
            <Nav className="me-auto">
              <Nav.Link
                className="text-secondary px-3 px-lg-2 px-xl-3 body-text2 main-nav-links"
                href={`/`}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={`my-1 ${(isScrolled) ? "text-black" : param1 === "" ? "text-white" : "text-black"} manrope-700`}
                >
                  Home
                </div>
                {param1 === "" && (
                  <div
                    className={`${isScrolled ? "border-underline-black" : param1 === "" ? "border-underline-white" : "border-underline-black"} mt-1`}
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link>

              <Nav.Item
                className="cursor-pointer text-white px-3 px-lg-2 px-xl-3 body-text2 main-nav-links"
                onMouseLeave={() => {
                  setSolutionDropdown(false);
                }}
                onMouseEnter={() => {
                  setSolutionDropdown(true);
                }}
              >
                <Nav.Link
                  className="body-text2 main-nav-links"
                  href={`/solutions`}
                  style={{ textDecoration: "none" }}
                >
                  <div className={`my-1 ${(isScrolled) ? "text-black" : param1 === "" ? "text-white" : "text-black"} manrope-700`}>
                    Solutions <FiChevronDown size="22" />
                  </div>
                  {param1 === "solutions" && (
                    <div
                      className={`${isScrolled ? "border-underline-black" : param1 === "" ? "border-underline-white" : "border-underline-black"} mt-1`}
                      style={{ width: 20 }}
                    />
                  )}
                </Nav.Link>
                <Card
                  className={`position-absolute d-flex solutions-container ${solutionDropdown ? "d-block" : "d-none"
                    }`}
                  style={{
                    zIndex: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #EFF0FA",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Body
                    className="p-2"
                    style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
                  >
                    <Row>
                      {solutions
                        ?.filter((item) => item.is_visible)
                        ?.map((item, index) => {
                          return (
                            <div className="col-sm-6" key={item.title}>
                              <a
                                href={`/solutions/${item?.slug}`}
                                className="text-decoration-none w-100"
                              >
                                <Col
                                  sm={4}
                                  className={`${solutionHover && dropdownSolution === index
                                    ? "text-primary fw-bold"
                                    : "text-black"
                                    } text-decoration-none body-text2 my-1 p-1 px-2 w-100`}
                                  onMouseEnter={() => {
                                    setSolutionHover(true);
                                    setDropdownSolution(index);
                                  }}
                                  onMouseLeave={() => {
                                    setSolutionHover(false);
                                    setDropdownSolution(null);
                                  }}
                                  state={{ previousPage: location.pathname }}
                                >
                                  {item?.title}
                                </Col>
                              </a>
                            </div>
                          );
                        })}
                    </Row>
                  </Card.Body>
                </Card>
              </Nav.Item>
              <Nav.Link
                className="px-3 px-lg-2 px-xl-3 body-text2 main-nav-links"
                href="/about-us"
                style={{ textDecoration: "none" }}
              >
                <div className={`${(isScrolled) ? "text-black" : param1 === "" ? "text-white" : "text-black"} my-1 manrope-700`}>About Us</div>
                {param1 === "about-us" && (
                  <div
                    className={`${isScrolled ? "border-underline-black" : param1 === "" ? "border-underline-white" : "border-underline-black"} mt-1`}
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link>
              {/* <Nav.Link
                className="px-3 px-lg-2 px-xl-3 body-text2 main-nav-links"
                href="/clients"
                style={{ textDecoration: "none" }}
              >
                <div className={`${(isScrolled) ? "text-black" : param1 === "" ? "text-white" : "text-black"} my-1 manrope-700`}>Clients</div>
                {param1 === "clients" && (
                  <div
                    className={`${isScrolled ? "border-underline-black" : param1 === "" ? "border-underline-white" : "border-underline-black"} mt-1`}
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link> */}
              {/* <Nav.Link
                className="text-white px-3 px-lg-2 px-xl-3 body-text2 main-nav-links"
                href="/blogs"
                style={{ textDecoration: "none" }}
              >
                <div className={`${(isScrolled) ? "text-black" : param1 === "" ? "text-white" : "text-black"} my-1 manrope-700`}>Blogs</div>
                {param1 === "blogs" && (
                  <div
                    className={`${isScrolled ? "border-underline-black" : param1 === "" ? "border-underline-white" : "border-underline-black"} mt-1`}
                    style={{ width: 20 }}
                  />
                )}
              </Nav.Link> */}
            </Nav>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="focus-none p-1 border-0"
            >
              <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" />
            </Navbar.Toggle>

            <Navbar.Collapse
              id="navbarScroll"
              className={
                large ? "d-flex justify-content-end" : "bg-extradark pb-4"
              }
              style={{ borderRadius: 10 }}
            >
              <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <a
                  className="d-flex align-items-center text-decoration-none my-3"
                  href={`https://wa.me/+919930329749`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    className="bg-secondary primary-button border-0 manrope-600 me-3"
                    style={{ width: 44, height: 44, borderRadius: 22 }}
                  >
                    <FaWhatsapp size={18} />
                  </Button>
                </a>
                <a
                  href="tel:+919930329749"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    className="bg-secondary primary-button border-0 manrope-600 me-3"
                    style={{ width: 44, height: 44, borderRadius: 22 }}
                  >
                    <MdPhone size={18} />
                  </Button>
                </a>
                <a
                  href="mailto:nirmal@gemtechs.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    className="bg-secondary primary-button border-0 manrope-600 me-3"
                    style={{ width: 44, height: 44, borderRadius: 22 }}
                  >
                    <GoMail size={18} />
                  </Button>
                </a>
                <Button
                  className="bg-secondary text-black primary-button border-0 manrope-600"
                  style={{
                    paddingLeft: 26,
                    paddingRight: 26,
                    borderRadius: 24,
                    height: 48,
                  }}
                  onClick={() => navigate(`/contact-us`)}
                >
                  Contact Us
                </Button>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Breakpoint>
      <Breakpoint medium down>
        <Navbar
          className={`d-flex flex-column w-100 ${isScrolled || param1 !== "" ? "bg-primary" : ""}`}
          fixed="top"
        >
          <Container
            fluid
            className="px-0 mx-0"
            style={{ width: 95 + "%" }}
          >
            <div>
              {(isScrolled || param1 !== "") &&
                <Navbar.Brand href="/" className="p-0 d-flex align-items-center">
                  <Image src={gemtechTextLogo} width={60} loading="lazy" />
                </Navbar.Brand>
              }
            </div>
            <div className="d-flex">
              <Button
                size="lg"
                className="d-flex justify-content-center align-items-center border-0 bg-transparent p-0"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <IoIosMenu size={30} color="#ffffff" />
              </Button>
            </div>
          </Container>
        </Navbar>
        {transition(
          (props, item) =>
            item && (
              <Modal
                className="custom-modal"
                contentClassName="custom-modal-dialog"
                dialogClassName="custom-modal-dialog"
                show={open}
                style={{
                  overflow: "hidden",
                  borderRadius: "0px",
                }}
              >
                <Modal.Header
                  style={{
                    border: "none !important",
                    borderBottom: "none !important",
                  }}
                >
                  <Navbar
                    className="d-flex flex-column w-100 bg-primary"
                    fixed="top"
                  >
                    <Container
                      fluid
                      className="px-0 mx-0"
                      style={{ width: 95 + "%" }}
                    >
                      <Navbar.Brand
                        href="/"
                        className="p-0 d-flex align-items-center"
                      >
                        <Image src={gemtechTextLogo} width={60} loading="lazy" />
                      </Navbar.Brand>
                      <div className="d-flex">
                        <Button
                          size="lg"
                          className="d-flex justify-content-center align-items-center border-0 bg-transparent p-0"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <RxCross2 size={25} color="#FFFFFF" />
                        </Button>
                      </div>
                    </Container>
                  </Navbar>
                </Modal.Header>
                <Modal.Body className="overflow-scroll">
                  <Container
                    as={animated.div}
                    className="position-fixed overflow-scroll pb-5 w-100 bg-secondary noScrollBar"
                    fluid
                    style={{
                      width: "100%",
                      height: "100%",
                      y: props.y,
                      top: 85,
                      left: 0,
                      transition: "height 0.5s ease-in-out",
                      zIndex: 999,
                    }}
                  >
                    <Nav
                      className={`ms-auto mt-1 d-flex align-items-start justify-content-center flex-column d-lg-none`}
                    >
                      <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/"
                        onClick={() => setOpen(false)}
                        style={param1 === "" ? activeBar : nonactiveBar}
                      >
                        <h3 className="manrope-500 p-activeBar0 m-0">Home</h3>
                      </Nav.Link>
                      {/* <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/solutions"
                        onClick={() => setOpen(false)}
                        style={param1 === "solutions" ? activeBar : nonactiveBar}
                      >
                        <h3 className="manrope-500 p-0 m-0">Solutions</h3>
                      </Nav.Link> */}
                      <Nav.Link
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        onClick={() => {
                          setSolutionHover(!solutionHover);
                        }}
                        style={param1 === "solutions" ? activeBar : nonactiveBar}
                      >
                        {!solutionHover && (
                          <h3 className="text-primary p-0 m-0">
                            Solutions
                            <AiFillCaretDown
                              width={"12px"}
                              height={"6px"}
                              top={"14.25px"}
                              left={"18px"}
                            ></AiFillCaretDown>
                          </h3>
                        )}
                        {solutionHover && (
                          <h3 className="text-primary p-0 m-0">
                            Solutions
                            <AiFillCaretUp
                              width={"12px"}
                              height={"6px"}
                              top={"14.25px"}
                              left={"18px"}
                            ></AiFillCaretUp>
                          </h3>
                        )}
                      </Nav.Link>
                      {solutionHover &&
                        solutions.filter(i => i.is_visible).map((item) => {
                          return (
                            <Nav.Link
                              as={Link}
                              className="m-2 d-flex flex-column justify-content-center align-items-center"
                              to={`/solutions/${item.slug}`}
                              onClick={() => {
                                setOpen(false)
                                setSolutionHover(false)
                              }}
                            >
                              <h4 className="poppins-500 p-0 ps-4 m-0">{item.title}</h4>
                            </Nav.Link>
                          )
                        })
                      }
                      <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/about-us"
                        onClick={() => setOpen(false)}
                        style={param1 === "about-us" ? activeBar : nonactiveBar}
                      >
                        <h3 className="manrope-500 p-0 m-0">About Us</h3>
                      </Nav.Link>
                      {/* <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/clients"
                        onClick={() => setOpen(false)}
                        style={param1 === "clients" ? activeBar : nonactiveBar}
                      >
                        <h3 className="manrope-500 p-0 m-0">Clients</h3>
                      </Nav.Link> */}
                      {/* <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/blogs"
                        onClick={() => setOpen(false)}
                        style={param1 === "blogs" ? activeBar : nonactiveBar}
                      >
                        <h3 className="manrope-500 p-0 m-0">Blogs</h3>
                      </Nav.Link> */}

                      <hr className="w-100" />

                      <div className="m-3 text-primary d-flex flex-column justify-content-center align-items-center">
                        <h3 className="manrope-500 p-0 m-0"
                        // onClick={() => {
                        //   setOpen(false)
                        // }}
                        >
                          <FaWhatsapp size={20} /> Message
                        </h3>
                      </div>
                      <div className="m-3 text-primary d-flex flex-column justify-content-center align-items-center">
                        <h3 className="manrope-500 p-0 m-0"
                        // onClick={() => {
                        //   setOpen(false)
                        // }}
                        >
                          <MdPhone size={20} /> Call
                        </h3>
                      </div>
                      <a
                        href="mailto:nirmal@gemtechs.in"
                        className="m-3 text-primary d-flex flex-column justify-content-center align-items-center text-decoration-none"
                      >
                        <h3 className="manrope-500 p-0 m-0">
                          <GoMail size={20} /> Mail
                        </h3>
                      </a>
                      <Nav.Link
                        as={Link}
                        className="m-2 d-flex flex-column justify-content-center align-items-center"
                        to="/contact-us"
                        onClick={() => setOpen(false)}
                        style={
                          param1 === "contact-us" ? activeBar : nonactiveBar
                        }
                      >
                        <h3 className="manrope-500 p-0 m-0">
                          Contact Us
                        </h3>
                      </Nav.Link>
                    </Nav>
                  </Container>
                </Modal.Body>
              </Modal>
            )
        )}
      </Breakpoint>
    </>
  );
};

export default NavBar;
