import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { tabletingMachine, tableting } from "assets";
import { FeaturePoints, ShareCard } from "components";

const Tableting = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const silentFeatures = [
        "Turret is made up of Special Grade S.G. Iron casting with Aluminium Turret Guards",
        "Upper & Lower Punch Penetration",
        "Effective Dust Extraction Nozzles",
        "Variable Speed drive, one shot Lubrication System, Anti Vibrating mounts",
        "Reverse direction motion protection is provided to avoid accident",
        "Single/Double Rotary Tablet Press, with interchangeable Turret",
        "Main & Pre compression of 10 Tons. (100 KN)",
        "Motorized dozer, for precise depth fill adjustment",
        "Motorized Force Feeders with zero clearance. With three paddle design",
        "Industrial PC / HMI, having 12” Colour Touch Screen",
        "The range of AWC is divided into Control, Reject & Data Storage",
        "The faulty tablet will be rejected automatically",
        "All the data like production count, tablet rejection, samples taken, batch time segment etc. will be recorded",
        "Discharge chute with three compartments like initial reject, auto reject & sampling gate"
    ]

    const tabletAncillaryMachines = [
        "Tablet Dusting Machine",
        "Metal Detector",
        "Dust Extraction System",
        "Vacuum Transfer System (For Automatic Powder Loading)",
        "Punches & Dies (Tablet tooling)",
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={tableting}
                    alt="Tableting"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Tableting</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className={`pt-5 ${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={6} lg={6}>
                            <Image
                                className="w-100"
                                src={tabletingMachine}
                                alt="Tableting Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                The tablet compression machine is a mechanical device, which by use of
                                compression force converts powder granules into tablets of uniform size and
                                weight, which is widely used in the different industries like Food, Pharma,
                                Nutraceutical, Detergent, Cosmetic, etc.
                            </div>
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                In Pharma Tablet compression machine, usually the granulated material is supplied
                                to the cavity through force feeder rotated at variable speed. The Cavity is fixed
                                in the turret accompanied by upper & lower punch which helps to form a tablet of
                                desired shape & size, the compaction force can be adjusted manually/automatically
                                based on model of the machine.
                            </div>
                        </Col>
                    </Row>
                    <h3>Salient Features :</h3>
                    <FeaturePoints items={silentFeatures} />
                    <h3 className="mt-4">Tablet Ancillary Machines :</h3>
                    <FeaturePoints items={tabletAncillaryMachines} />
                </Container>
            </Container>
        </>
    );
};

export default Tableting;
