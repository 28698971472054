import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { contactUsCover, contactUs } from "assets";
import { ContactUsForm } from "components";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";

const ContactUs = () => {

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const communication = [
    {
      id: 1,
      icon: <HiOutlineMail color="#eabe21" size={20} />,
      title: "Email",
      description: "Our team is here to help.",
      info: "info@gemtechs.in",
    },
    {
      id: 2,
      icon: <FiPhone color="#eabe21" size={20} />,
      title: "Phone",
      description: "Mon-Sat from 9am to 6pm.",
      info: "+91 99303 29749",
    },
    {
      id: 3,
      icon: <HiOutlineLocationMarker color="#eabe21" size={20} />,
      title: "Office",
      description: "Head Office in Thane, service worldwide.",
      info: "Thane - 400615",
    },
  ];

  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center px-0 py-3"
        style={{ minHeight: "40vh" }}
      >
        <Image
          className="position-absolute w-100 h-100"
          src={contactUsCover}
          alt="Contact Us cover"
          style={{ objectFit: "cover", zIndex: "-1" }}
        />
        <div
          className="position-absolute w-100 h-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
        ></div>
        <Container
          className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
            }`}
        >
          <div className="page-header text-white my-lg-3 my-2">Contact Us</div>
        </Container>
      </Container>
      <Container fluid className="bg-white mt-5 px-0" style={{ minHeight: "100vh" }}>
        <Container className="my-5 pt-5">
          <Row>
            <Col sm={12} md={12} lg={6} className="pe-lg-5">
              <h3 className="pe-3">
                Our team is available for you worldwide
              </h3>
              <div className="subtitle-400 my-4">
                Have any questions or need to connect with our experts? Our global network of Gemtech sales and service locations is here to assist you.
              </div>
              <ContactUsForm />
            </Col>
            <Col lg={6} className={`d-flex justify-content-center ${large ? "" : "d-none"}`}>
              <Image src={contactUs} style={{ borderRadius: 16, maxHeight: 900 }} />
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="bg-secondary bg-secondary d-flex justify-content-center align-items-center mb-5"
          style={{ minHeight: "30vh" }}
        >
          <Container>
            <Row>
              {communication.map((comm) => {
                return (
                  <Col
                    sm={4}
                    className="d-flex flex-column justify-content-center align-items-center my-4"
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: 48,
                        height: 48,
                        border: "8px solid #FFFFFF",
                        borderRadius: 24,
                      }}
                    >
                      {comm.icon}
                    </div>
                    <b className="mt-4">{comm.title}</b>
                    <div className="mb-4">{comm.description}</div>
                    <div className="text-primary">
                      <b>{comm.info}</b>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default ContactUs;
