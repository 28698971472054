import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { granulation, granulationRapid } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const RapidMixerGranulator = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Optimized bowl capacity utilization for enhanced efficiency",
        "Ensures leak-proof operation with effective sealing",
        "Equipped with PLC and HMI-based control for seamless operation",
        "Variable Frequency Drive (VFD) at both Impeller and Chopper for precise control",
        "Pneumatically operated Top Lid for user-friendly access",
        "Effortless product discharge through Pneumatically operated system",
        "Vacuum-rated bowl design facilitates material charging via Vacuum Transfer System",
        "Safety ensured with sensors at Top Lid and guard",
        "3 Blade Impeller and 4 Blade Chopper design for superior granule formation",
        "Air purging at both impeller and chopper shaft for cleanliness",
        "Endpoint indication provided by Ampere measurement",
        "21 CFR Part 11 compliance available on request for regulatory adherence"
    ]

    const optionalEnhancements = [
        "Wet Co-mill at discharge with VFD, enabling efficient wet milling",
        "Flame Proof Construction for enhanced safety in specific environments",
        "Integration of Spray Ball Assembly for ease of Wet In-Place (WIP) cleaning",
        "Swivel-type top lid design for convenient access and operation",
        "Automatic Binder Addition System for precise and automated binder incorporation",
        "SCADA connectivity for seamless monitoring and control",
        "Closed integrated transfer system for airtight material transfer",
        "Recipe Management System for systematic and automated recipe handling"
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={granulation}
                    alt="Granulation"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Granulation</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Rapid Mixer Granulator</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/granulation">
                            Granulation
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Rapid Mixer Granulator
                        </span>
                    </div>
                    <Row className={`${medium ? "" : "mb-5"}`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={granulationRapid}
                                alt="Rapid Mixer Granulator Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                The unparalleled capabilities of our Rapid Mixer Granulator (RMG) are ideal for
                                processes requiring seamless mixing, precise granule size control, and significant
                                time savings. The dynamic interplay of Mixing Blades and Chopper Blades, meticulously
                                designed with the right profiles and speeds, guarantees uniformly mixed granules
                                of active ingredients and excipients, seamlessly integrated with binders. Achieve
                                precise dose distribution effortlessly through an automated binder addition system,
                                paired with optimal speed configurations for the Mixing and Chopper Blades.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Enhancements :</h3>
                    <FeaturePoints items={optionalEnhancements} />
                </Container>
            </Container>
        </>
    );
};

export default RapidMixerGranulator;
