import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { gearPumpLiquidFilling, liquidFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const GearPumpLiquidFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Rigid construction with a stainless-steel structure and high-grade anodized aluminum.",
        "Lubricant oil pump for precise and smooth filling.",
        "Servo motor controls each filling nozzle individually, ensuring precision in the filling process.",
        "Selected pneumatic shut-off valve to prevent liquid from dripping down, maintaining cleanliness.",
        "Pneumatic bottle positioning system and diving nozzle for accurate and controlled filling.",
        "Pneumatic drip tray with a surplus oil backflow system, minimizing wastage and ensuring cleanliness."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Gear Pump Liquid Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Gear Pump Liquid Filling
                        </span>
                    </div>
                    <Row className={`${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={gearPumpLiquidFilling}
                                alt="Gear Pump Liquid Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                Automatic Gear Pump Liquid Filling Machine, a high-speed, multi-head liquid filler
                                designed for efficiently meeting lubricant oil filling requirements. With a filling
                                capacity ranging from 1 to 5 litres, this machine combines power with precision. The
                                servo motor control ensures accuracy, allowing for individual adjustments for each
                                nozzle. Experience seamless and efficient liquid filling tailored for your lubricant
                                oil needs.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default GearPumpLiquidFilling;
