import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { blisterPacking, blisterPackingMachine, cartoningMachine, } from "assets";
import { FeaturePoints, ShareCard } from "components";

const BlisterPackingCartoning = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const blisterFeatures = [
        "Handles a wide range of thermoform films and cold-form packaging foils",
        "Edge-to-edge blister-pack punching",
        "Effortless downstream integration with various cartoning machines",
        "Rotary indexing suitable for large blister sizes",
        "Easy access and cleaning, for hassle-free changeover",
        "Suitable for a variety of blister pack sizes"
    ]

    const cartoningSailentFeatures = [
        "The machine adopts servo motors and is of the continuous & intermediate type.",
        "The carton hopper can hold thousands of cartons as per customer requirements.",
        "Automatically rejects cartons that lack a leaflet or blister; if no blister is present, the machine will not feed the carton or leaflet.",
        "Features a touch screen with language options in English or other local languages as per customer needs.",
        "Automatically stops if the blister is incorrectly positioned in the carton, without needing to press the emergency button.",
        "Includes a joystick for remote control, making it more convenient for workers to change carton sizes.",
        "Displays trouble alerts, alarms, and finished product counts automatically.",
        "Low maintenance with easy access to all components for straightforward upkeep.",
        "Optional: Inkjet printer, hot melt glue machine, bar code reader."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={blisterPacking}
                    alt="Blister Packing & Cartoning"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Blister Packing & Cartoning</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className={`pt-5 ${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={6} lg={5}>
                            <Image
                                className="w-100 border"
                                src={blisterPackingMachine}
                                alt="Blister Packing Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-5">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={7}>
                            <h3 className="text-tertiary">Blister Packing</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                Our blister-packaging range provides variety of solutions to meet customers latest packaging
                                needs. Our offerings encompass high-performance & reliable machines, for various
                                packaging materials such as thermoforming films and cold-form foils. Meanwhile, precision-driven
                                machines equipped with the latest servo technology ensure robust forming and sealing capabilities,
                                delivering enhanced speed and accuracy. Additionally, we offer a secure containment option that
                                prioritises operator safety, particularly for highly potent pharmaceutical ingredients.
                                Moreover, our compact and versatile machines excel in short-run and small-batch blister-pack
                                production, accommodating various configurations and materials.
                            </div>
                        </Col>
                    </Row>
                    <div className={`subtitle text-gray ${large ? "mb-4" : "my-4"}`} style={{ textAlign: 'justify' }}>
                        Our Blister packing machine remains the preference for customers with many active blister
                        packaging installations globally. It is a flat-sealing/ rotary sealing machine fully capable
                        with the full range of thermoform films / cold-form packaging foils. Powered by servo technology,
                        it ensures accuracy, reliability and control, and hence improves productivity.
                    </div>
                    <h3>Features :</h3>
                    <FeaturePoints items={blisterFeatures} />
                </Container>
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className="my-lg-5 pt-lg-5">
                        <Col sm={12} md={6} lg={5}>
                            <Image
                                className="w-100 border"
                                src={cartoningMachine}
                                alt="Cartoning Machine"
                                style={{ borderRadius: 20 }}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={7} style={{ textAlign: 'justify' }}>
                            <h3 className="text-tertiary">Cartoning</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                We have Variety of Semiautomatic and Automatic Cartoning Machine of various output as per
                                customers’ requirements. These machines are widely used in pharmaceutical companies which
                                need as per production capacity. The leaflet folding device is optional, the design is similar
                                with GUK device which can fold the leaflet stably or we can adopt the GUK leaflet folding
                                device. The machine has the function of printing the batch number, and the inkjet printer
                                is optional. The Cartoner machine sealing the carton with tuck-in or hot melt glue device.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                The Automatic Cartoning Machine includes the parts as: automatic blister feeding device,
                                product inspection, unqualified product rejection, carton sucking and shaping, product pushing
                                by the pushing rod, leaflet folding system, carton closing or hot glue sealing, batch No
                                printing, unqualified final product rejection system.
                            </div>
                        </Col>
                    </Row>
                    <h3>Sailent Features :</h3>
                    <FeaturePoints items={cartoningSailentFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default BlisterPackingCartoning;
