import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MissionAndVision } from "components";
import { aboutCoverImage } from "assets";

const AboutUs = () => {
  const navigate = useNavigate();
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="about-us-hero-background d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "100vh", background: "linear-gradient(-10deg, #eabe21 50%, #ffffff 50%)" }}
      >
        <Container className="py-5 text-center">
          <h2 style={{ fontWeight: 700 }}>Strategic Growth Solutions</h2>
          <div className="d-flex justify-content-center align-items-center">
            <div className="bg-primary" style={{ width: 200, height: 5 }} />
          </div>
          <Image src={aboutCoverImage} className="img-fluid w-100 my-5" />
          <div className="align-items-center">
            <div className="subtitle text-black">
              Gemtech is a top provider of machinery and integrated system solutions, specializing
              in the development, production, and packaging of pharmaceuticals, nutraceuticals, food,
              cosmetics, and chemical products. Offering everything from a single source, we provide
              strategic growth solutions tailored to your needs.
            </div>
          </div>
        </Container>
      </Container>
      <MissionAndVision />
    </Container>
  );
};

export default AboutUs;
