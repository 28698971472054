import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { doubleHeadTubeFilling, liquidFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const DoubleHeadTubeFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "Filling Capacity: Versatile filling capacity ranging from 5 gm to 200 gm of cream/ointment.",
        "Tube Sealing/Closing: Capable of sealing/closing Lami tubes up to 40 mm diameter and Aluminium tubes up to 28 mm diameter.",
        "Oval Race Track: Features an oval race track with Aluminium tube holder for precise handling.",
        "Fully Enclosed Body: The body is fully enclosed from all sides, providing added safety if required.",
        "Premium Cladding: Cladded with pre-buffed SS sheet for a durable and aesthetic exterior.",
        "Conveyor Protection: Overload clutch protection on the conveyor ensures safety and prevents overloading.",
        "Barcode Reading Provision: Optional provision for barcode reading, enhancing traceability if required.",
        "Stainless Steel Construction: All stationary parts above the tabletop are made of SS 304, ensuring hygiene and durability.",
        "Variable Frequency Drive: Equipped with a variable frequency drive for speed adjustment.",
        "Advanced Control System: Incorporates PLC and touch screen HMI for seamless operation and control.",
        "Hardened Cams and Bearing Support: All cams are hardened, and shafts are supported on bearings for robust performance.",
        "Material Quality: Contact parts are made of SS 316, and non-contact parts are made of SS 304, ensuring compatibility and longevity."
    ]

    const optionalFeatures = [
        "Saddle Fold: Option to include a saddle fold feature, enhancing tube presentation and aesthetics.",
        "Multi-Color Filling: Capability for multi-color filling, providing flexibility in product presentation.",
        "Designer Seal in Plastic Tubes: Option for designer seals in plastic tubes, enhancing branding and visual appeal.",
        "Gas Flushing Before & After Filling: Incorporation of gas flushing before and after filling, contributing to product freshness and optimising shelf life."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Double Head Tube Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Double Head Tube Filling
                        </span>
                    </div>
                    <Row className={`my-5`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={doubleHeadTubeFilling}
                                alt="Double Head Tube Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8} style={{ textAlign: 'justify' }}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                The Fully Automatic Tube Filling Machine is designed for the efficient filling
                                and sealing of tubes, accommodating both LAMI and Plastic tube materials. Widely
                                utilized in the Pharmaceutical, Cosmetics, Chemical, Agriculture, and Food industries,
                                this machine is versatile and caters to a broad range of applications.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Capable of filling various materials, including Cosmetic Creams, Pastes, Honey,
                                Gel, Gum, Balm, Ointments, etc., into LAMI, Plastic, or Aluminium tubes, this
                                machine streamlines the entire process in a single operation with indexing. The
                                operation includes tube feeding, filling, sealing, and coding (manufacturing date,
                                expiry, lot number, etc.).
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Our product line includes both fully automatic and semi-automatic tube filling
                                machines, catering to a diverse range of viscosity levels. Materials handled by
                                our machines encompass paint, adhesive, cosmetics, lotion, toothpaste, shampoo,
                                and more. Our machine stands as a reliable and versatile solution for a wide
                                spectrum of tube-filling applications.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Features :</h3>
                    <FeaturePoints items={optionalFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default DoubleHeadTubeFilling;
