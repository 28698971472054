import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { encapsulation, encapsulationMachine } from "assets";
import { FeaturePoints, ShareCard } from "components";

const Encapsulation = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const salientFeatures = [
        "Conforms to cGMP guidelines",
        "Precise slug dosing principle ensures high fill accuracy",
        "Pellet, tablet, Softgel, micro tablets, etc. can be filled in capsule with additional attachments.",
        "All the product contact parts are made up of SS 316L",
        "Better yield due to low dusting and minimal wastage",
        "Microdosing capability (up to 15 mg ± 2mg)",
        "Fills powder/pellets in capsules of any size from #000 to 5",
        "Easy and quick capsule size and product changeover",
        "Easy to operate touch screen (MMI)",
        "Smart in-built logic & alarm for operator safety",
        "All the doors & drive covered with interlock sensor"
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={encapsulation}
                    alt="Encapsulation"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Our Solutions</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Encapsulation</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <Row className={`pt-5 ${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={6} lg={5}>
                            <Image
                                className="w-100 border"
                                src={encapsulationMachine}
                                alt="Encapsulation Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-5">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={7} style={{ textAlign: 'justify' }}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4">
                                We offers a wide range of Capsule filling machines as per customers requirements.
                                We provide capsule filling machines for Powder filling, Liquid filling, Containment
                                filling for highly potent formulations, and special attachments for combination
                                filling. Our encapsulation machines meet the demands of small scale &  R&D level
                                machines to  large-scale pharmaceutical and nutraceutical manufacturing without
                                compromising on quality. The machine comes with quick changeover, to minimise downtime
                                and to achieve maximum  productivity.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Capsule filling machine is used to fill the powder, pellets, tablets, liquid etc into
                                hard gelatine capsule by tamping principle, the machine is widely used in Pharmaceutical
                                & Nutraceutical industries.
                            </div>
                            <div className="subtitle text-gray mt-4">
                                Powder of desired bulk & tap density filled inside the dosing disc with the help of
                                powder auger, the free-flowing powder in then compressed in to cavity of dosing disc
                                with help of series of tamping piston. The machine adopts tamping principle where 6
                                sets of tamping blocks given, 5 tamping blocks compresses the powder to form hard slug
                                and sixth tamping block delivers the powder in to capsule. Based on amount of powder
                                fill weight & bulk density thickness of dosing disc adjusted.
                            </div>
                        </Col>
                    </Row>
                    <h3>Salient Features :</h3>
                    <FeaturePoints items={salientFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default Encapsulation;
