import React from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { liquidFilling, volumetricLiquidFilling } from "assets";
import { FeaturePoints, ShareCard } from "components";
import { useMediaQuery } from "react-responsive";


const VolumetricLiquidFilling = () => {
    const navigate = useNavigate();
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const share_url = window.location.href;

    const keyFeatures = [
        "±1% filling accuracy on a single dose ensures precise and consistent filling.",
        "No bottle, no fill system for enhanced operational control.",
        "Rigid, vibration-free construction with an elegant matte finish body.",
        "Easy-to-operate control panel for user-friendly interactions.",
        "Minimal changeover time, facilitating swift transitions between container sizes or fill sizes.",
        "Self-lubricating UHMW-PE guide profile for SS slate chain, offering a low-friction, low-wear surface, ensuring smooth and noiseless conveying.",
        "Error code display on AC frequency drive or control panel for quick issue resolution.",
        "Drain tray around the machine platform for easy maintenance.",
        "Drip tray under the conveyor to manage spills effectively.",
        "Mechanical bottle stopping arrangement for precise filling.",
        "Synchronised main motor and conveyor belt A/C drives for optimal control."
    ]

    const optionalFeatures = [
        "Automatic chain washing system for the conveyor belt, ensuring thorough cleanliness.",
        "In-built pre-inspection of empty bottles, enhancing quality control.",
        "Acrylic cabinet for a polished and protective appearance.",
        "Acrylic cover on the conveyor, combining safety with visibility.",
        "Float tank (holding tank) with or without a stirrer, providing flexibility in liquid handling."
    ]

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center px-0 py-3"
                style={{ minHeight: "50vh" }}
            >
                <Image
                    className="position-absolute w-100 h-100"
                    src={liquidFilling}
                    alt="Liquid Filling"
                    style={{ objectFit: "cover", zIndex: "-1" }}
                />
                <div
                    className="position-absolute w-100 h-100"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "-1" }}
                ></div>
                <Container
                    className={`mt-5 pt-5 d-flex flex-column  ${large ? "justify-content-center align-items-center" : ""
                        }`}
                >
                    <div className="px-lg-3 text-primary body-text2">
                        <b>Liquid Filling</b>
                    </div>
                    <div className="page-header text-white my-lg-3 my-2">Volumetric Liquid Filling</div>
                    <div className="subtitle-400 text-secondary text-lg-center text-white mb-5">
                        Your needs, our expertise. Find the perfect solution.
                    </div>
                    <div
                        className={`d-flex justify-content-center align-items-center ${large ? "" : "flex-column"
                            }`}
                    >
                        <Button
                            className={`bg-transparent text-primary manrope-600 ${large ? "" : "my-4"
                                }`}
                            style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                borderRadius: 30,
                                height: 60,
                            }}
                            onClick={() => navigate(`/contact-us`)}
                        >
                            Contact Us
                        </Button>
                    </div>
                </Container>
            </Container>
            <Container fluid className="bg-white">
                <Container
                    className="my-5"
                    style={{ minHeight: "100vh" }}
                >
                    <div className="subtitle d-flex flex-row my-5 pt-5">
                        <a className="manrope-400 text-decoration-none" href="/solutions/liquid-filling">
                            Liquid Filling
                        </a>
                        <span className="text-gray">
                            {">"}
                        </span>
                        <span className="text-gray">
                            Volumetric Liquid Filling
                        </span>
                    </div>
                    <Row className={`${large ? "mt-5" : "my-5"}`}>
                        <Col sm={12} md={4} lg={4}>
                            <Image
                                className="w-100"
                                src={volumetricLiquidFilling}
                                alt="Volumetric Liquid Filling Machine"
                                style={{ borderRadius: 20 }}
                            />
                            <div className="mt-4">
                                <ShareCard share_url={share_url} bottom={true} />
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <h3 className="text-tertiary">Introduction</h3>
                            <div className="bg-primary" style={{ width: 100, height: 4 }} />
                            <div className="subtitle text-gray mt-4" style={{ textAlign: 'justify' }}>
                                The Automatic Volumetric Liquid Filling machines are meticulously designed to automate
                                the filling of bottles or jars with unparalleled precision. With a wide range of applications
                                in industries such as food, pharmaceuticals, and the oil sector, our machines are
                                engineered for optimal efficiency and effectiveness at their designed speed. Experience
                                seamless and accurate liquid filling for enhanced productivity in your operations.
                            </div>
                        </Col>
                    </Row>
                    <h3>Key Features :</h3>
                    <FeaturePoints items={keyFeatures} />
                    <h3 className="mt-4">Optional Features :</h3>
                    <FeaturePoints items={optionalFeatures} />
                </Container>
            </Container>
        </>
    );
};

export default VolumetricLiquidFilling;
